
.UploadImg {
  position: relative;
  display: flex;
  cursor: pointer;
  min-height: 120px;
  background-color: #cedad6;
  margin-bottom: 10px;

  .img {
    display: block;
    width: 100%;
  }

  .text {
    position: absolute;
    left: 0;
    top: 0;
    width: 272px;
    font-family: "Display Medium";
    text-align: center;
    color: white;
    stroke: white;

    svg {
      width: 21px;
      height: 21px;
    }
  }

  .input {
    position: absolute;
    opacity: 0;
  }
}
