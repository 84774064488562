.input-field {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    flex-direction: column;

    .label {
        font-family: "Display Regular";
        text-transform: uppercase;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        margin-top: 6px;
        margin-bottom: 0;
        color: #797979;
    }

    .input-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        border-radius: 6px;
        color: #28314d;

        .input {
            width: 100%;
            outline: 0;
            color: #000000;
            border-radius: inherit;
            border: 1px solid #bbbfc5;
            height: 24px;
            font-size: 12px;
            font-weight: 100;
            padding: 0 4px;
        }
    }
}
