
.RangeSlider {
  width: 100%;
  .wrapper {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .round {
    border-radius: 50%;
    &.small {
      background: #ff8a00;
      width: 9px;
      height: 9px;
    }
    &.mid {
      width: 14px;
      height: 14px;
      background: #c4c4c4;
      position: absolute;
      &.active {
        background: #ff8a00;
      }
    }
    &.big {
      width: 19px;
      height: 19px;
      background: #c4c4c4;
      position: absolute;
      &.active {
        background: #ff8a00;
      }
    }
  }
  .line {
    height: 2px;
    width: 100%;
    background: #c4c4c4;
    border-radius: 5px;
    &.active {
      background: #ff8a00;
    }
  }
  .middle-line,
  .big-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }
}
