* {
    box-sizing: border-box;
}

.ui-color-picker {
    display: flex;
    flex-direction: column;
    width: 280px;
    user-select: none;

    .gradient-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:flex-end;
        width: 100%;
        margin-bottom: 16px;
        height: 24px;
        padding: 0 16px;

        .gradient-type {
            flex: 1;
            display: flex;

            .gradient-type-item {
                height: 28px;
                width: 28px;
                border-radius: 50%;
                position: relative;
                cursor: pointer;

                &.active {
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -3px;
                        bottom: -3px;
                        left: -3px;
                        right: -3px;
                        border: 2px solid #1F2667;
                        border-radius: 50%;
                    }
                }

                &.liner-gradient {
                    background: linear-gradient(270deg, #FFFFFF 0%, #1F2667 100%);
                }

                &.radial-gradient {
                    margin-left: 8px;
                    background: radial-gradient(circle, #FFFFFF 0%, #1F2667 100%);
                }
            }

        }

        .gradient-degrees-options {
            position: relative;

            .gradient-degrees {
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                position: relative;
                width: 28px;
                height: 28px;
                border: 3px solid #1F2667;
                border-radius: 18px;
                margin-right: 54px;

                .gradient-degree-center {
                    position: relative;
                    width: 6px;
                    height: 22px;
                    pointer-events: none;

                    .gradient-degree-pointer {
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        top: 2px;
                        border-radius: 3px;
                        background: #1F2667;
                    }
                }
            }

            .gradient-degree-value {
                position: absolute;
                top: 0;
                right: 0;
                width: 48px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #bbbfc5;
                border-radius: 6px;

                p {
                    text-align: center;
                    padding: 0 6px;
                }
            }
        }
    }

    .picker-area {
        display: flex;
        flex-direction: column;
        padding: 0 16px;

        &.gradient-tab {
            .picking-area {
                margin-bottom: 10px;
            }
        }

        .picking-area {
            width: 100%;
            height: 160px;
            margin-bottom: 16px;
            position: relative;
            border-radius: 8px;

            &:hover {
                cursor: default;
            }

            .picking-area-overlay1 {
                height: 100%;
                width: 100%;
                background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
                border-radius: 3px;

                .picking-area-overlay2 {
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
                    border-radius: 8px;
                }
            }
        }

        .preview {
            display: flex;
            flex-direction: row;
            margin-bottom: 16px;

            .preview-box {
                box-sizing: border-box;
                height: 36px;
                width: 36px;
                border-radius: 8px;
                border: 1px solid #EBEDF5;
            }

            .color-hue-alpha {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-left: 6px;

                .hue {
                    width: 100%;
                    position: relative;
                    border-radius: 10px;
                    margin-bottom: 8px;
                    padding: 0 7px;
                    background-color: red;
                    cursor: pointer;

                    .hue-area {
                        position: relative;
                        height: 14px;
                        background: -webkit-linear-gradient(left, #ff0000, #ff0080, #ff00ff, #8000ff, #0000ff, #0080ff, #00ffff, #00ff80, #00ff00, #80ff00, #ffff00, #ff8000, #ff0000);
                        background: -o-linear-gradient(left, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
                        background: -ms-linear-gradient(left, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
                        background: -moz-linear-gradient(left, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
                        background: linear-gradient(to right, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
                    }
                }

                .alpha {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    border-radius: 10px;
                    height: 14px;
                    cursor: pointer;

                    .gradient {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    .alpha-area {
                        width: 100%;
                        height: 100%;
                        background: url("assets/images/alpha-background.svg");
                        background-size: auto;
                        background-position: 50% 50%;
                        border-radius: 10px;
                        padding: 0 7px;

                        .alpha-mask {
                            width: 100%;
                            height: 100%;
                            position: relative;
                        }
                    }
                }
            }
        }

        .gradient {
            width: 100%;
            height: 26px;
            position: relative;
            cursor: pointer;
            border-radius: 10px;
            margin-bottom: 16px;
            padding: 0 7px;

            .gradient-slider-container {
                height: 100%;
                width: 100%;
                position: relative;
                .picker-pointer {
                    height: 25px;
                    width: 25px;
                }
            }
        }

        .picker-pointer {
            position: absolute;
            top: 1px;
            height: 12px;
            width: 12px;
            border: 1px solid #FFFFFF;
            background: transparent;
            border-radius: 50%;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);

            .child-point {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 3px;
                width: 3px;
                background: #FFFFFF;
                border-radius: 50%;
                opacity: 0;
                transition: opacity 0.33s;

                &.active {
                    opacity: 1;
                }
            }
        }
    }

    .color-preview-area {
        margin-bottom: 8px;
        padding: 0 16px;

        .input-group {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .uc-field-group:not(:last-child) {
                margin-right: 7px;
            }
        }

        .hex {
            width: 65px;
        }

        .rgb {
            width: 40px;
        }
    }

    .colors {
        padding: 3px 16px 0;

        .colors-label {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            cursor: pointer;

            .uc-icon {
                margin-right: 8px;
                transition: transform 0.3s;
            }

            .tp-text {
                text-transform: uppercase;
            }

            &.show {
                & + .colors-item-container {
                    max-height: 80px;
                    padding-bottom: 16px;
                }

                .uc-icon {
                    transform: rotate(-90deg);
                }
            }
        }

        .template {
            display: flex;
            flex-direction: column;
        }

        .global {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .colors-item-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            transition: max-height 0.3s, padding-bottom 0.3s;
            max-height: 0;
            overflow: hidden;

            .colors-item {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                background-color: #EBEDF5;
                cursor: pointer;
                position: relative;
                margin-top: 4px;
                flex-shrink: 0;

                &:not(.plus) {
                    border: 1px solid #EBEDF5;
                }

                &.empty {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line {
                        width: 2px;
                        height: 16px;
                        background-color: #8892B3;
                        border-radius: 1px;
                        transform: rotate(45deg);
                    }
                }

                &.plus {
                    margin-bottom: 4px;

                    .uc-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 1;
                    }
                }

                &:not(:first-child):not(:nth-child(9)) {
                    margin-left: 8px;
                }

                .uc-icon {
                    position: absolute;
                    right: -8px;
                    top: -3px;
                    opacity: 0;
                    transition: opacity 0.3s;
                }

                &:hover {
                    .uc-icon {
                        opacity: 1;
                    }
                }

                &.active {
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -3px;
                        bottom: -3px;
                        left: -3px;
                        right: -3px;
                        border: 2px solid #8892B3;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
