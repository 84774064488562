
.pipette {
  margin: 4px;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background: #ececec;
    border: 1px solid transparent;
    .sucker {
      fill: #ff8025;
    }
  }
  .sucker {
    width: 30px;
    margin: -4px -4px;
    fill: #9099a4;
    transition: all 0.3s;
  }
  &.active {
    /*background: #ff8025;*/
    .sucker {
      fill: #ff8025;
    }
  }
}
